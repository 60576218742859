import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import styles from 'components/PRISMIC/scss/pages.module.scss';

import type { JSX } from 'react';

/**
 * Props for `HorizontalRule`.
 */
export type HorizontalRuleProps =
  SliceComponentProps<Content.HorizontalRuleSlice>;

/**
 * Component for "HorizontalRule" Slices.
 */
const HorizontalRule = ({ slice }: HorizontalRuleProps): JSX.Element => {
  return (
    <hr
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={styles['pages-hr']}
    />
  );
};

export default HorizontalRule;
